<!--
 * @Description:
 * @Author: laicheng
 * @Date: 2022-06-23 10:44:00
-->
<template>
  <div v-if="!isMini">
    <template v-for="(item, index) in list" :key="index">
      <img v-if="item.path == ''" :src="item.url" alt="" class="img"
        style="width:100%;object-fit:contain;display:block">
      <div v-else>
        <wx-open-launch-weapp id="launch-btn" username="gh_5bac9e9f9a1a" :path="item.path">
          <div v-is="'script'" type="text/wxtag-template">
            <img style="margin:0;padding:0;width:100%;object-fit:contain;display:block" :src="item.url" alt=""
              class="img">
          </div>
        </wx-open-launch-weapp>
      </div>
    </template>
  </div>
  <div v-else>
    <template v-for="(item, index) in list" :key="index">
      <img :src="item.url" @click="goToMini(item.path)" alt="" class="img"
        style="width:100%;object-fit:contain;display:block">
    </template>
  </div>

</template>
<script setup>
import qs from 'qs'
import { onMounted, ref } from 'vue'
import { fetchWeb } from '@/API/web'
import { imagePath } from '@/config'
import { useRoute } from 'vue-router'
import { authTag } from '../../utils/wechat'

const list = ref([])
const isMini = ref(true)
const route = useRoute()
const str = ref('')
onMounted(async () => {
  str.value = navigator.userAgent
  if (navigator.userAgent.indexOf('mini') > -1) {
    isMini.value = true
  } else {
    isMini.value = false
    authTag()
  }
  fetchItem()
})

async function fetchItem() {
  const item = await fetchWeb({ id: route.query.id })
  document.title = item.title
  list.value = JSON.parse(item.content).map(v => {
    if (v.url != null) {
      v.url = imagePath + v.url
    } else {
      v.url = ''
    }
    const query = route.query
    delete query.id
    v.path = v.path + qs.stringify(query)
    return v
  })
}

function goToMini(path) {
  wx.miniProgram.navigateTo({ url: path })
}
</script>
<style lang="scss">
</style>
