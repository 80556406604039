/*
 * @Description:
 * @Author: laicheng
 * @Date: 2022-06-23 14:50:05
 */
module.exports = {
  imagePath: 'https://oss.meijinguanjia.com',
  uploadPath: 'https://upload.meijinguanjia.com/upload',
  appid: 'wx94d65eaa711a0bc0',
  app_secret: '7bd241843a734cdadf94d452e38739b2'
}
