/*
 * @Description:
 * @Author: laicheng
 * @Date: 2022-06-23 13:43:36
 */
import request from '@/utils/request'

export function fetchWeb(data) {
  return request({
    url: `/webview/${data.id}`
  })
}
